<template>
  <app-button
    v-bind="$attrs"
    :block="block"
    :color="color"
    :size="size"
    :to="{
      name: 'contact',
      query: {
        origin,
        origin_page_action,
        origin_page_id,
        redirect,
        confirmation_toast: `${confirmationToast ?? true}`,
      },
    }"
    :variant="variant"
    @click="onClick"
  >
    <slot> {{ $t("growth.contact_button.default_label") }} </slot>
  </app-button>
</template>

<script lang="ts" setup>
import type { ButtonProps } from "@pollen/ui-library/client/shared/button/button.model";

import type { ContactFlowMetadata } from "~~/model/analytics.model";

defineOptions({
  inheritAttrs: false,
});

const properties = withDefaults(
  defineProps<
    ButtonProps &
      ContactFlowMetadata & { redirect?: string; confirmationToast?: boolean }
  >(),
  {
    redirect: undefined,
    confirmationToast: true,
  },
);

const { $analytics } = useNuxtApp();

function onClick() {
  $analytics.track("call_flow_started", {
    origin: properties.origin,
    origin_page_id: properties.origin_page_id,
    origin_page_action: properties.origin_page_action,
  });
}
</script>
